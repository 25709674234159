<template>
  <div id="support-center">
    <div class="vx-row justify-center">
      <div class="vx-col w-full md:w-3/5">
        <div class="vx-row">
          <!-- Catalog -->
          <div class="vx-col cataloge w-full sm:w-11/12">
            <div class="vx-card">
              <div slot="no-body" class="h-full">
                <img
                  :src="require('@/assets/images/guide_1.png')"
                  alt="cataloge"
                />
                <img
                  :src="require('@/assets/images/guide_2.png')"
                  alt="cataloge"
                />
              </div>
            </div>
          </div>

          <!-- Social -->
          <div class="vx-col social-media w-full sm:w-1/12">
            <a
              :href="item.link"
              target="_blank"
              v-for="(item, index) in socials"
              :key="index"
            >
              <img
                :src="require(`@/assets/images/social/${item.name}.png`)"
                :alt="item.name"
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      socials: "auth/socials"
    })
  }
};
</script>

<style lang="scss">
@import "./support.scss";
</style>
